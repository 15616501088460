<template>
  <div
    v-cloak
    class="panel"
    :class="[getClass, (panel_data.button_link && !panel_data.button_text) ? 'whole-panel-link' : '']"
    @click="
      panel_data.button_link && !panel_data.button_text
        ? gotoPage(panel_data.button_link, 'external')
        : null
    "
  >
    <div class="panel-data">
      <h3>{{ getrans(panel_data.heading) }}</h3>
      <p v-html="getrans(panel_data.brief_description)"></p>
      <a
        target="_blank"
        rel="noopener noreferrer"
        class="panel-data__button"
        :href="getrans(panel_data.button_link)"
        v-if="panel_data.button_text"
        >{{ getrans(panel_data.button_text) }}</a
      >
    </div>
    <div class="panel-image">
      <img :src="panel_data.image" v-if="panel_data.image" />
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
export default {
  name: "Panel",
  props: {
    panel_data: undefined,
    lang: undefined,
    translations: undefined,
    brandSlug: undefined,
  },
  computed: {
    getClass: function () {
      switch (this.panel_data.image_align) {
        case "right":
          return "image-right";
        case "left":
          return "image-left";
        case "top":
          return "image-top";
        case "bottom":
          return "image-bottom";
        case "center":
          return "image-center";
        default:
          return "no-image";
      }
    },
  },
  data() {
    return {};
  },
  methods: {
    getrans(string) {
      if (!string) return;
      string = string.trim();
      var s = md5(string);
      var ss = this.brandSlug + "-" + this.lang.toUpperCase() + "-" + s;
      //console.log('panel', ss);
      return this.translations[ss] || string;
    },
    gotoPage(url, type = null) {
      if (url.indexOf("myaccountdev.") > -1 && this.lang) {
        url += "?lang=" + this.lang;
      }
      if (url.indexOf("myaccount.") > -1 && this.lang) {
        url += "?lang=" + this.lang;
      }
      if (type && type.toLowerCase() === "external") {
        window.open(url);
      } else {
        window.location.href = url;
      }
    },
  },
  updated() {},
};
</script>

<style scoped lang="scss">

[v-cloak] { display: none; }

@import "@/assets/sass/variables.scss";

.whole-panel-link {
  cursor: pointer;
}

.panel {
  display: flex;
  &.image-right {
    flex-direction: row;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    .panel-data {
      padding-right: 20px;
      width: 70%;

      @media screen and (max-width: 767px) {
        width: 100%;
        padding-bottom: 20px;
        padding-right: 0;
      }
    }

    .panel-data__button {
      display: inline-block;
      padding: 1rem 3rem;
      margin: 0.5rem 0;
      border: 2px solid #1a2a40;
      color: #1a2a40;
      font-weight: bold;
      @media screen and (max-width: 767px) {
        display: block;
        text-align: center;
      }
    }

    .panel-image {
      width: 30%;
      text-align: center;

      img {
        // max-height: 250px;
        height: 100%;
        width: auto;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  &.image-left {
    flex-direction: row-reverse;

    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }

    background: #fff;
    box-shadow: 0px 3px 6px #00000029;

    h3 {
      font-size: 1.5rem;
      padding-bottom: 1rem;
      color: #034a94;
      font-weight: bold;
    }

    .panel-data {
      width: 70%;
      padding: 2rem;

      @media screen and (max-width: 767px) {
        width: 100%;
        // padding-bottom: 20px;
        padding: 1rem;
        // padding-left: 0;
      }
    }

    .panel-image {
      // width: 30%;

      @media screen and (max-width: 767px) {
        // width: 100%;
        margin: 0 auto;
      }
    }
  }

  &.image-top {
    flex-direction: column-reverse;
    justify-content: flex-end;
    box-shadow: 0px 3px 6px #00000029;
    padding: 0rem;
    background: #fff;
    width: 100%;
    min-width: 240px;
    @media screen and (max-width: 959px) {
      max-width: 320px;
    }

    .panel-image {
      max-height: 100%;
      @media screen and (min-width: 960px) {
        //max-height: 250px;
      }
    }

    .panel-data {
      padding: 1rem;
      @media screen and (min-width: 960px) {
        padding: 2rem;
      }
      h3 {
        font-size: 1.5rem;
        padding-bottom: 1rem;
        color: #26345D;
        font-weight: bold;
      }
      a {
        border: 2px solid;
        display: block;
        text-align: center;
        @media screen and (min-width: 960px) {
          display: inline-block;
        }
        margin-top: 0.5rem;
        padding: 0.5rem 2rem;
        color: #26345D;
        font-weight: bold;
      }
    }
  }

  &.image-bottom {
    flex-direction: column;

    .panel-data {
      padding-bottom: 20px;
    }
  }


  &.image-center {
    flex-direction: column;

    .panel-image {
      text-align: left;
      width: 100%;
      border-radius: 10px;
     // max-width: 1000px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .panel-data {
      text-align: left;
      width: 100%;
      padding: 20px;
      @media screen and (max-width: 767px) {
        padding: 10px;
      }
    }
  }

  &.no-image {
    .panel-image {
      display: none;
    }
  }

  .panel-image {
    // max-height: 250px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
}
</style>
