import { createApp } from 'vue'
import App from './Index.vue'

import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';
import { VueCookieNext } from 'vue-cookie-next';
import SlideUpDown from 'vue3-slide-up-down';

let app = createApp(App);

app.use(PrimeVue);
app.use(VueCookieNext);
VueCookieNext.config({ expire: '1m' });
app.component('Dialog', Dialog);
app.component('slide-up-down', SlideUpDown);

app.mount('#app');
